<template>
  <div class="social">

    <img class="opcite animated fadeIn" src="../static/img/about/social/social-icon.png" height="76" width="288"/>
    <div class="fourth">
      <div class="fy_center_box second_top">
        <div class="row">
          <div
              :class="['top_btn',currentIndex==index?'active':'']"
              v-for="(item,index) in personList"
              :key="index"
              @mousemove="changeIndex(index)"
          >{{item.name}}</div>
        </div>
      </div>

      <!-- bounceInLeft -->
      <div
          :class="['table_box animated',isChange?'fadeInLeft':'fadeOutRight']"
          v-for="(p,index) in personList"
          :key="index"
          v-show="currentIndex==index&&isChange"
      >
        <div class="fy_center_box">
          <div class="person">

            <img src="../static/img/about/social/social2.png" v-show="index==0"  height="450" width="600"/>
            <img src="../static/img/about/social/social1.png" v-show="index==1"  height="450" width="600"/>
            <img src="../static/img/about/social/social3.png" v-show="index==2"  height="450" width="600"/>
          </div>
        </div>
      </div>
      <div class="table_right_box">
        <div
            :class="['table_right animated',currentIndex==index?'fadeInRight':'fadeInLeft']"
            v-for="(p,index) in personList"
            :key="index"
            v-show="currentIndex==index&&isChange"
        >

          <div class="des">
            <div class="tit">{{p.tit}}</div>
            <div v-for="(h,m) in p.des" :key="m">
              {{h.val}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNav from "@/components/Nav/TopNav.vue";
  import Footer from "@/components/Footer/Footer.vue";

  export default {
    data() {
      return {
        personList: [
          {
            name: "可持续发展",
            tit:'人工智能赋能中医药大时代，中医人工智能创新发展既是民生工程，也是产业发展方向。',
            des: [
              {val:'新一代人工智能正在全球范围内蓬勃兴起，为经济社会发展注入了新动能，正在深刻改变人们的生产生活方式。习近平总书记强调“中国愿在人工智能领域与各国共推发展、共护安全、共享成果。” 中国正致力于实现高质量发展，人工智能发展应用将有力提高经济社会发展智能化水平，有效增强公共服务和城市管理能力。十九大以来，党和国家已经把中医药事业的发展和大健康产业的升级，提升到国家战略的高度。大势已定，当传统中医药与现代人工智能大数据激情相遇，必将激发中医药创新活力，突破传统瓶颈，带动中医药全面复兴。'},
              // {val:'实现中医健康服务与互联网技术的整合，同时建成省级和国家级标准化的中医药大数据中心，为中医药传承发展提供科学数据支撑。'},
              // {val:'现代化人工智能应用到中医药领域，提高医生的工作效率。同时也能助力中医人才培养模式改革，为中医药师生提供科学优质的学习平台。'},
            ]
          },
          {
            name: "绿色环保",
            tit:'与环境和谐共处',
            des: [
              {val:'经方云遵循“诚信、和谐、开放、共赢”核心价值观，坚持节能环保、环境保护与可持续发展。'}
            ]
          },
          {
            name: "社会公益",
            tit:'社会公益',
            des: [
              {val:'企业依靠社会发展起来，也要反过来帮助社会发展。作为一个具有强烈社会责任感的公司，经方云认真履行企业公民责任，积极支援灾区重建，热心参与扶贫济困、安老助孤、帮困助学等慈善事业，以己所能及之力回馈社会。'}
            ]
          }
        ],
        currentIndex: 0,
        isChange: true

      };
    },
    methods: {
      changeIndex(index) {
        // if (this.isChange) {
        // return;
        // }
        this.currentIndex = index;
      }
    },
    watch: {
      currentIndex(val) {
        this.isChange = true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .social {
    padding-top: 50px;
    .bottom-box{
      width: 100%;
      height: 577px;
      margin: 0 auto;
      background: url("./../static/img/about/honor/honor-bg.png") no-repeat center;
      background-size: cover;
      clear: both;
      padding-top: 80px;
      .bottom-list{
        width: 1200px;
        height: 400px;
        margin: 0 auto;
        .bottom-item{
          display: inline-block;
          width: 372px;
          height: 400px;
          margin: 0 10px;
          border-radius: 20px;
          .bottom-item1{
            font-size: 18px;
            color: #333333;
            font-weight: 550;
            margin: 15px 0;

          }
          .bottom-item2{
            font-size: 14px;
            font-weight: 550;

          }

        }

      }
    }
    .fourth {
      position: relative;
      padding-top: 20px;
      padding-bottom: 80px;
      height: 750px;
      text-align: center;
      overflow: hidden;
      .second_top {
        position: relative;
        height: 60px;
        margin-top: 60px;
        z-index: 9;
        .row {
          display: flex;
          justify-content: space-between;
          width: 640px;
          height: 100%;
          overflow: hidden;
          margin: 0 auto;
          .top_btn {
            display: inline-block;
            width: 190px;
            height: 100%;
            line-height: 60px;
            font-size: 18px;
            text-align: center;
            color: #AB7D3E;
            background-image: url("./../static/img/product/p-bg1.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            //background-color: #666666;
            &:hover {
              cursor: pointer;
            }
          }
          .top_btn.active {
            background-image: url("./../static/img/product/p-bg2.png");
            color: #fff;
          }
        }
      }
      .title {
        font-size: 30px;
        color: #191e4a;
        letter-spacing: 4px;
      }

      .table_box {
        position: absolute;
        top: 160px;
        left: 0;
        width: 100%;
        overflow: hidden;
        .person {
          position: relative;
          top: 20px;
          display: block;
          float: left;
          //width: 500px;
          //height: 550px;
          overflow: hidden;
          margin-top: 0px;
          // padding-left: 200px;
        }
      }
      .table_right_box {
        width: 1200px;
        white-space: nowrap;
        margin: 0 auto;
      }

      .table_right {
        display: inline-block;
        width: 1200px;
        height: 556px;
        // margin: 0 auto;
        padding-top: 30px;
        white-space: normal;

        .des {
          display: block;
          width: 540px;
          color: #5b607f;
          letter-spacing: 1.2px;
          text-align: left;
          line-height: 36px;
          padding-top: 20px;
          font-size: 16px;
          text-indent: 40px;
          float: right;
          .tit{
            font-size: 24px;
            font-weight: 550;
            text-indent: 0;
            margin-bottom: 30px;
          }
        }
        .row_des {
          display: block;
          width: 540px;
          height: 260px;
          margin-top: 30px;
          margin-right: 50px;
          float: right;
        }
      }
    }


  }
  //.opcite{
  //  opacity: 1;
  //  transform: 1s;
  //}
</style>
