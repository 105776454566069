import { render, staticRenderFns } from "./corporate.vue?vue&type=template&id=0bd5c652&scoped=true"
import script from "./corporate.vue?vue&type=script&lang=js"
export * from "./corporate.vue?vue&type=script&lang=js"
import style0 from "./corporate.vue?vue&type=style&index=0&id=0bd5c652&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd5c652",
  null
  
)

export default component.exports