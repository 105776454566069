<template>
  <div class="develop">
    <div class="first">
      <div class="first-box">
        <div style="text-align: center;margin-bottom: 30px">
          <img class="opcite animated fadeIn" src="../static/img/about/social/devlop-icon.png" height="76" width="410"/>
        </div>
        <div>
          <img class="opcite animated fadeIn" style="margin: 0 auto;" src="../static/img/about/social/devlop-2.png" height="2492" width="893"/>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNav from "@/components/Nav/TopNav.vue";
  import Footer from "@/components/Footer/Footer.vue";

  export default {
    data() {
      return {

      };
    },
    methods: {

    }
  };
</script>

<style lang="scss" scoped>
  .develop {
    background-color: #282828;
    .develop-box{
    }
  }
  .first{
    .first-box{
      width: 1200px;
      height: 2800px;
      padding-top: 80px;
      margin: 0 auto;
    }
    width: 100%;
    height: 2800px;
    background: url("./../static/img/about/social/devlop-bg.png") no-repeat center;
    background-size: cover;
    clear: both;
  }
</style>
