<template>
  <div class="honor">

    <img class="honor-img animated fadeIn" src="../static/img/about/honor/honor-icon.png" height="76" width="159"/>
    <div class="honor-box">
      <img class="animated fadeInRight" src="../static/img/about/honor/honor-1.png" height="373" width="969"/>
      <img v-show="scroll1" ref="scrolla" class="honor-img" src="../static/img/about/honor/honor-2.png" height="373" width="969"/>
      <img v-show="scroll2" ref="scrollb" src="../static/img/about/honor/honor-3.png" height="373" width="969"/>
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>

    <div class="bottom-box">
      <div class="bottom-list">
        <el-carousel :interval="2000" type="card" height="400px">
<!--          <el-carousel-item v-for="item in 6" :key="item">-->
<!--            <el-card class="bottom-item" shadow="always">-->
<!--              <img src="../static/img/about/honor/honor-img1.png" height="290" width="372"/>-->
<!--              <div class="bottom-item1">中医人工智能深度学习实验室(CMAI-LAB)</div>-->
<!--              <div class="bottom-item2">与中国科学院计算研究所合作共同成立</div>-->
<!--            </el-card>-->
<!--          </el-carousel-item>-->
          <el-carousel-item>
            <el-card class="bottom-item" shadow="always">
              <img src="../static/img/about/honor/1.jpg" height="290" width="372"/>
              <div class="bottom-item1">中医人工智能深度学习实验室(CMAI-LAB)</div>
              <div class="bottom-item2">与中国科学院计算研究所合作共同成立</div>
            </el-card>
          </el-carousel-item>
          <el-carousel-item>
            <el-card class="bottom-item" shadow="always">
              <img src="../static/img/about/honor/2.jpg" height="290" width="372"/>
              <div class="bottom-item1">张仲景经方研究实验室（CPRL）</div>
              <div class="bottom-item2">与中国中医药信息学会张仲景分会共同成立</div>
            </el-card>
          </el-carousel-item>
          <el-carousel-item>
            <el-card class="bottom-item" shadow="always">
              <img src="../static/img/about/honor/3.jpg" height="290" width="372"/>
              <div class="bottom-item1">中国中医药信息学会张仲景研究分会</div>
              <div class="bottom-item2">理事单位</div>
            </el-card>
          </el-carousel-item>
          <el-carousel-item>
            <el-card class="bottom-item" shadow="always">
              <img src="../static/img/about/honor/4.jpg" height="290" width="372"/>
              <div class="bottom-item1">2019智慧医疗创新大赛</div>
              <div class="bottom-item2">荣获最佳原创奖</div>
            </el-card>
          </el-carousel-item>
          <el-carousel-item>
            <el-card class="bottom-item" shadow="always">
              <img src="../static/img/about/honor/5.jpg" height="290" width="372"/>
              <div class="bottom-item1">河南省软件服务业协会</div>
              <div class="bottom-item2">理事单位</div>
            </el-card>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        scroll1:false,
        scroll2:false,
      };
    },
    mounted() {
      window.addEventListener('scroll',this.handleScroll,true)
    },
    methods: {
      handleScroll(){
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // console.log(scrollTop)
        if (scrollTop >= 500 && !this.scroll1) {
          this.scroll1 = true
          this.$refs.scrolla.classList.add('animated', 'fadeInLeft')
        }
        if (scrollTop >= 800 && !this.scroll2) {
          this.scroll2 = true
          this.$refs.scrollb.classList.add('animated', 'fadeInRight')
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll',this.handleScroll,true)
    },
  };
</script>

<style lang="scss" scoped>
  .honor {
    padding-top: 50px;
    .honor-box{
      width: 1200px;
      height: 1280px;
      margin: 50px auto;
      .honor-img{
        margin: 50px 0;
      }
    }
    .bottom-box{
      width: 100%;
      height: 577px;
      margin: 0 auto;
      background: url("./../static/img/about/honor/honor-bg.png") no-repeat center;
      background-size: cover;
      clear: both;
      padding-top: 80px;
      .bottom-list{
        width: 1200px;
        height: 400px;
        margin: 0 auto;
        .bottom-item{
          display: inline-block;
          width: 372px;
          height: 400px;
          margin: 0 10px;
          border-radius: 20px;
          .bottom-item1{
            font-size: 18px;
            color: #333333;
            font-weight: 550;
            margin: 15px 0;

          }
          .bottom-item2{
            font-size: 14px;
            font-weight: 550;

          }

        }

      }
    }
    /deep/ .el-card__body{
      padding: 0 !important;
      border-radius: 20px;
    }

  }
  .node-img1{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: 10px;

  }
  /deep/ .el-carousel__indicators--outside{
    visibility: hidden;
  }
</style>
