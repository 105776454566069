<template>
  <div class="corporate">

    <img class="honor-img animated fadeIn" src="../static/img/about/corporate/corporate-icon2.png" height="76" width="380"/>
    <div></div>
    <img  class="honor-img animated bounceIn" style="margin: 70px auto" src="../static/img/about/corporate/corporate-img.png" height="660" width="1196"/>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>

    <div class="bottom-box">
      <img v-show="scroll1" ref="scrolla" src="../static/img/about/corporate/corporate-icon3.png" height="76" width="239"/>
      <div class="bottom-list" v-show="scroll3" ref="scrolld">
          <el-card class="bottom-item" shadow="always">
            <div class="bottom-item1" v-for="(p,q) in list">
              <div class="bottom-item-note">●</div>
              <div class="bottom-item-val">{{ p.val }}</div>
            </div>
          </el-card>
      </div>
    </div>
    <div class="end-box">
      <img v-show="scroll2" ref="scrollb" src="../static/img/about/corporate/corporate-icon1.png" height="76" width="330"/>
      <div v-show="scroll2" ref="scrollc" class="end-list">
        <img src="../static/img/about/corporate/corporate-1.png" height="520" width="256"/>
        <img src="../static/img/about/corporate/corporate-2.png" height="520" width="256"/>
        <img src="../static/img/about/corporate/corporate-3.png" height="520" width="256"/>
        <img src="../static/img/about/corporate/corporate-4.png" height="520" width="256"/>
        <img src="../static/img/about/corporate/corporate-5.png" height="520" width="256"/>
        <img src="../static/img/about/corporate/corporate-6.png" height="520" width="256"/>
        <img src="../static/img/about/corporate/corporate-7.png" height="520" width="256"/>
        <img src="../static/img/about/corporate/corporate-8.png" height="520" width="256"/>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNav from "@/components/Nav/TopNav.vue";
  import Footer from "@/components/Footer/Footer.vue";

  export default {
    data() {
      return {
        list:[
          {val:'这是最好的时代，智能化的时代，生命最具价值的时代。'},
          {val:'这是中医药搭乘互联网快车，开启智慧中医药的大时代。'},
          {val:'实现管理学、祖国传统医学、现代医学、互联网技术、人工智能技术、云计算技术、生命科学等多门学科完美结合。'},
          {val:'以传承祖国医学瑰宝、让中医惠及全人类7000000000多人口的健康事业为己任。'},
        ],
        scroll1:false,
        scroll2:false,
        scroll3:false,

      };
    },
    mounted() {
      window.addEventListener('scroll',this.handleScroll,true)
    },
    methods: {
      handleScroll(){
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // console.log(scrollTop)
        if (scrollTop >= 600 && !this.scroll1) {
          this.scroll1 = true
          this.$refs.scrolla.classList.add('animated', 'rotateInDownRight')
        }
        if (scrollTop >= 1000 && !this.scroll3) {
          this.scroll3 = true
          this.$refs.scrolld.classList.add('animated', 'swing')
        }
        if (scrollTop >= 1550 && !this.scroll2) {
          this.scroll2 = true
          this.$refs.scrollb.classList.add('animated', 'rotateInDownLeft')
          this.$refs.scrollc.classList.add('animated', 'bounceIn')
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll',this.handleScroll,true)
    },
  };
</script>

<style lang="scss" scoped>
  .corporate {
    padding-top: 50px;
    .bottom-box{
      width: 100%;
      height: 472px;
      margin: 0 auto;
      background: url("./../static/img/about/corporate/corporate-bg.png") no-repeat center;
      background-size: cover;
      clear: both;
      padding-top: 80px;
      .bottom-list{
        width: 1200px;
        height: 400px;
        margin: 0 auto;
        padding-top: 50px;
        .bottom-item{
          display: inline-block;
          width: 923px;
          height: 325px;
          margin: 0 10px;
          padding: 50px 50px;
          border-radius: 20px;
          .bottom-item1{
            font-size: 18px;
            color: #333333;
            margin: 15px 0;
            text-align: left;
            display: flex;
            justify-content: left;
            align-items: first;
            line-height: 23px;
            .bottom-item-note{
              width: 30px;
              font-size: 40px;
              color: #666666;
            }
            .bottom-item-val{
              width: 790px;
              font-size: 22px;
              line-height: 30px;
              color: #666666;
            }

          }
          .bottom-item2{
            font-size: 14px;
            font-weight: 550;
          }
        }
      }
    }
    /deep/ .el-card__body{
      padding: 0 !important;
      border-radius: 20px;
    }
    .end-box{
      width: 100%;
      height: 1572px;
      margin: 0 auto;
      padding-top: 130px;
      .end-list{
        width: 1200px;
        margin: 0 auto;
        img{
          margin: 56px 20px;
        }
      }
    }

  }
  .node-img1{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: 10px;

  }
</style>
