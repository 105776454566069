<template>
  <div class="core">
    <img  class="second-box1 animated fadeIn" src="../static/img/about/core/core.png" height="76" width="326"/>

    <div class="core-box">
      <div @mouseover="onMouseOver(1)" class="core-item core-box1">
        <div class="img-top" v-show="index === 1 ">
          <p class="core-tit">技术创新团队</p>
          <div class="core-txt">
            ▉ 中科院计算研究所专家组
          </div>
          <div class="core-txt">
            ▉ 中医科学院中医大数据中心研究组；
          </div>
          <div class="core-txt">
            ▉ 信息化专业团队20余人，其中高级以上工程师6人。
          </div>
        </div>
      </div>
      <div @mouseover="onMouseOver(2)" class="core-item core-box2">
        <div class="img-top" v-show="index === 2 ">
          <p class="core-tit">权威专家团队</p>
          <div class="core-txt">
            ▉ &nbsp;&nbsp;&nbsp;张仲景研究专家组；
          </div>
          <div class="core-txt">
            ▉ &nbsp;&nbsp;&nbsp;河南经方研究组；
          </div>
          <div class="core-txt">
            ▉ &nbsp;&nbsp;&nbsp;国医大师及名医名家；
          </div>
          <div class="core-txt">
            ▉ &nbsp;&nbsp;&nbsp;具有高级职称的中医团队20余人，其中博士6人，硕士12人。
          </div>

        </div>
      </div>
      <div @mouseover="onMouseOver(3)" class="core-item core-box3">
        <div class="img-top" v-show="index === 3 ">
          <p class="core-tit">科研实验室</p>
          <div class="core-txt">
            ▉ &nbsp;&nbsp;&nbsp;经方诊断实验室
          </div>
          <div class="core-txt">
            ▉ &nbsp;&nbsp;&nbsp;大数据实验室
          </div>
          <div class="core-txt">
            ▉ &nbsp;&nbsp;&nbsp;人工智能实验室
          </div>

        </div>
      </div>
      <div @mouseover="onMouseOver(4)" class="core-item core-box4">
        <div class="img-top" v-show="index === 4 ">
          <p class="core-tit">精准度保障</p>
          <div class="core-txt core-txt1">
            技术创新团队和权威专家团队相结合，将经方研究的成果与AI技术、大数据及云计算完美对接，经过多年反复临床试验，反馈修改，保障结果精准度。
          </div>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
  import TopNav from "@/components/Nav/TopNav.vue";
  import Footer from "@/components/Footer/Footer.vue";

  export default {
    data() {
      return {
        index: 1,
        oldIndex: 0,
        timer: null,
        transform: true,
        time: new Date().getTime()

      };
    },
    methods: {
      onMouseOver(val){
        let nowTime = new Date().getTime()
        if (nowTime - this.time < 200) {
          return
        }
        this.index = val
        this.time = nowTime
        if (this.index !== this.oldIndex){
          this.transform = true

        }
        let itemImg2 = document.getElementsByClassName('core-box2')[0]
        let itemImg1 = document.getElementsByClassName('core-box1')[0]
        let itemImg3 = document.getElementsByClassName('core-box3')[0]
        let itemImg4 = document.getElementsByClassName('core-box4')[0]
        // if (this.transform){
        //   this.transform = false
        //   this.timer = setTimeout( ()=>{
            if (val===1){
              itemImg1.style.backgroundImage = 'url('+require("@/static/img/about/core/core-img-1.png")+')'
              itemImg1.style.width = '600px'
              itemImg2.style.backgroundImage = 'url('+require("@/static/img/about/core/core-2.png")+')'
              itemImg2.style.width = '200px'
              itemImg3.style.backgroundImage = 'url('+require("@/static/img/about/core/core-3.png")+')'
              itemImg3.style.width = '200px'
              itemImg4.style.backgroundImage = 'url('+require("@/static/img/about/core/core-4.png")+')'
              itemImg4.style.width = '200px'
            }
            if (val===2){
              itemImg1.style.backgroundImage = 'url('+require("@/static/img/about/core/core-1.png")+')'
              itemImg1.style.width = '200px'
              itemImg2.style.backgroundImage = 'url('+require("@/static/img/about/core/core-img-2.png")+')'
              itemImg2.style.width = '600px'
              itemImg3.style.backgroundImage = 'url('+require("@/static/img/about/core/core-3.png")+')'
              itemImg3.style.width = '200px'
              itemImg4.style.backgroundImage = 'url('+require("@/static/img/about/core/core-4.png")+')'
              itemImg4.style.width = '200px'
            }
            if (val===3){
              itemImg1.style.backgroundImage = 'url('+require("@/static/img/about/core/core-1.png")+')'
              itemImg1.style.width = '200px'
              itemImg2.style.backgroundImage = 'url('+require("@/static/img/about/core/core-2.png")+')'
              itemImg2.style.width = '200px'
              itemImg3.style.backgroundImage = 'url('+require("@/static/img/about/core/core-img-3.png")+')'
              itemImg3.style.width = '600px'
              itemImg4.style.backgroundImage = 'url('+require("@/static/img/about/core/core-4.png")+')'
              itemImg4.style.width = '200px'
            }
            if (val===4){
              itemImg1.style.backgroundImage = 'url('+require("@/static/img/about/core/core-1.png")+')'
              itemImg1.style.width = '200px'
              itemImg2.style.backgroundImage = 'url('+require("@/static/img/about/core/core-2.png")+')'
              itemImg2.style.width = '200px'
              itemImg3.style.backgroundImage = 'url('+require("@/static/img/about/core/core-3.png")+')'
              itemImg3.style.width = '200px'
              itemImg4.style.backgroundImage = 'url('+require("@/static/img/about/core/core-img-4.png")+')'
              itemImg4.style.width = '600px'
            }
          //   this.oldIndex = val
          //   this.transform = true
          //   clearTimeout(this.timer)
          //   this.timer = null
          // },300)

        // }

      }
    }
  };
</script>

<style lang="scss" scoped>
  .core {
    padding-top: 50px;
    .core-box{
      width: 1200px;
      height: 540px;
      //background-color: #AE7B40;
      margin: 50px auto;
      .core-item{
        display: inline-block;
        height: 540px;
        position: relative;
        text-align: left;
        color: white;
        .img-top{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(0,0,0,0.5);
          padding: 20px;
          height: 200px;
          .core-tit{
            font-size: 26px;
            margin-bottom: 10px;
          }
          .core-txt{
            width: 600px;
            font-size: 16px;
            margin-top: 5px;
          }
          .core-txt1{
            text-indent: 30px;
            line-height: 25px;
            width: 530px;
          }
        }

      }
      .core-box1{
        width: 600px;
        background: url("./../static/img/about/core/core-img-1.png") no-repeat center;
        background-size: cover;
        clear: both;
        transition: 0.3s;

      }
      .core-box2{
        width: 200px;
        background: url("./../static/img/about/core/core-2.png") no-repeat center;
        background-size: cover;
        clear: both;
        transition: 0.3s;
      }
      .core-box3{
        width: 200px;
        background: url("./../static/img/about/core/core-3.png") no-repeat center;
        background-size: cover;
        clear: both;
        transition: 0.3s;
      }
      .core-box4{
        width: 200px;
        background: url("./../static/img/about/core/core-4.png") no-repeat center;
        background-size: cover;
        clear: both;
        transition: 0.3s;
      }
      .core-box11:hover{
        width: 600px;
        background: url("./../static/img/about/core/core-img-1.png") no-repeat center;
        background-size: cover;
        clear: both;
        .core-box2{
          width: 200px;
          background: url("./../static/img/about/core/core-2.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box3{
          width: 200px;
          background: url("./../static/img/about/core/core-3.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box4{
          width: 200px;
          background: url("./../static/img/about/core/core-4.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
      }
      .core-box12:hover{
        width: 600px;
        background: url("./../static/img/about/core/core-img-2.png") no-repeat center;
        background-size: cover;
        clear: both;
        .core-box1{
          width: 200px;
          background: url("./../static/img/about/core/core-1.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box3{
          width: 200px;
          background: url("./../static/img/about/core/core-3.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box4{
          width: 200px;
          background: url("./../static/img/about/core/core-4.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
      }
      .core-box13:hover{
        width: 600px;
        background: url("./../static/img/about/core/core-img-3.png") no-repeat center;
        background-size: cover;
        clear: both;
        .core-box1{
          width: 200px;
          background: url("./../static/img/about/core/core-1.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box2{
          width: 200px;
          background: url("./../static/img/about/core/core-2.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box4{
          width: 200px;
          background: url("./../static/img/about/core/core-4.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
      }
      .core-box14:hover{
        width: 600px;
        background: url("./../static/img/about/core/core-img-4.png") no-repeat center;
        background-size: cover;
        clear: both;
        .core-box1{
          width: 200px;
          background: url("./../static/img/about/core/core-1.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box3{
          width: 200px;
          background: url("./../static/img/about/core/core-3.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
        .core-box2{
          width: 200px;
          background: url("./../static/img/about/core/core-2.png") no-repeat center;
          background-size: cover;
          clear: both;
        }
      }
    }

  }
</style>
