<template>
  <div class="about">
    <TopNav></TopNav>
    <div class="first">
      <div class="top-tab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="公司简介" name="first"></el-tab-pane>
          <el-tab-pane label="核心能力" name="second"></el-tab-pane>
          <el-tab-pane label="发展历程" name="third"></el-tab-pane>
          <el-tab-pane label="荣誉资质" name="fourth"></el-tab-pane>
          <el-tab-pane label="企业理念" name="fourth1"></el-tab-pane>
          <el-tab-pane label="社会责任" name="fourth2"></el-tab-pane>
        </el-tabs>
        <div class="top-tit">关于我们</div>
      </div>
    </div>
    <Int v-if="index === '0'"></Int>
    <CoreCompetence v-if="index === '1'"></CoreCompetence>
    <Develop v-if="index === '2'"></Develop>
    <Honor v-if="index === '3'"></Honor>
    <Corporate v-if="index === '4'"></Corporate>
    <Social v-if="index === '5'"></Social>
    <div v-if="false" class="fifth">
      <div class="fy_center_box">
        <p class="title">获取更多资讯</p>
        <p class="under_tit">请留下您的联系方式</p>
        <img src="@/static/image/about/under_title.png" style="width:52px;height:2px;"/>
        <div class="form_box">
          <div class="top_box">
            <div class="input_box">
              <label class="required" for>姓名：</label>
              <input type="text" v-model="formData.name"/>
            </div>
            <div class="input_box">
              <label class="required" for>电话：</label>
              <input type="number" v-model="formData.phone"/>
            </div>
            <div class="input_box">
              <label for>邮箱：</label>
              <input type="text" v-model="formData.email"/>
            </div>
          </div>
          <div class="center_box">
            <label class="required" for>留言：</label>
            <textarea cols="30" id name rows="10" style="resize: none;" v-model="formData.content"></textarea>
          </div>
          <img @click="submitFn" class="submit_btn" src="@/static/image/about/submit.png"/>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import TopNav from "@/components/Nav/TopNav.vue";
  import Footer from "@/components/Footer/Footer.vue";
  import Int from "@/components/int.vue";
  import CoreCompetence from "@/components/coreCompetence.vue";
  import Honor from "@/components/honor.vue";
  import Develop from "@/components/develop.vue";
  import Corporate from "@/components/corporate.vue";
  import Social from "@/components/social.vue";

  export default {
    data() {
      return {
        activeName: 'first',
        formData: {
          name: "",
          phone: "",
          email: "",
          content: ""
        },
        isLoading: false,
        index:'0'
      };
    },
    components: {
      TopNav,
      Footer,
      Int,
      CoreCompetence,
      Honor,
      Develop,
      Corporate,
      Social
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
        this.index = tab.index
        console.log(this.index)
      },
      submitFn() {
        if (this.isLoading) {
          return;
        }
        let Util = new this.$Util();
        if (!Util.checkName(this.formData.name)) {
          this.$message.error("姓名填写有误，请正确填写后再次提交！");
          return;
        }
        if (!Util.checkPhone(this.formData.phone)) {
          this.$message.error("手机号填写有误，请正确填写后再次提交！");
          return;
        }
        if (this.formData.email) {
          if (!Util.checkMail(this.formData.email)) {
            this.$message.error("邮箱填写有误，请正确填写后再次提交！");
            return;
          }
        }
        if (!this.formData.content) {
          this.$message.error("请检查留言内容是否填写！");
          return;
        }
        this.isLoading = true;
        this.$Api
          .addMsg(this.formData)
          .then(res => {
            console.log(res);
            this.isLoading = false;
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.formData = {};
            }
          })
          .catch(err => {
            this.isLoading = false;
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .about {
    .first {
      padding-top: 10px;
      margin-top: 80px;
      width: 100%;
      height: 360px;
      background: url("./../static/img/about/int/bg1.png") no-repeat center;
      background-size: cover;
      clear: both;
      .top-tab{
        width: 1200px;
        margin: 0 auto;
        color: white;
      }
      .top-tit{
        color: white;
        font-size: 16px;
        text-align: left;
        width: 150px;
        position: relative;
        top: -50px;
        left: 0;
      }

      /deep/ .el-tabs__nav-wrap::after{

        height: 1px !important;
        background-color: #E4E7ED;
      }
      /deep/ .el-tabs__nav{
        padding-left: 560px;
      }
      /deep/ .el-tabs__active-bar{
        margin-left: 560px;

      }
      /deep/ .el-tabs__item{
        color: white;
        font-size: 16px;
        height: 46px;
        padding: 0 20px;
      }
      /deep/.el-tabs__active-bar{
        background-color: #fff !important;
        height: 3px;
      }
      /deep/.is-active{
        font-weight: 550;
        font-size: 16px;
      }

    }
    .second {
      position: relative;
      padding-top: 52px;
      padding-bottom: 80px;
      width: 1200px;
      margin: 0 auto;
      height: 800px;
      text-align: center;
      //overflow: hidden;
      .second-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .second-box1{
          width: 556px;
          padding-right: 50px;
          .second-box1-2{
            color: #666666;
            font-size: 16px;
            text-indent: 40px;
            text-align: left;
            line-height: 30px;
          }
        }
        .second-box2{
          width: 745px;
        }
        .second-box3{
          width: 400px;
        }
      }
      .bottom-box{
        margin-top: 30px;
      }
    }
    .third{
      padding-top: 50px;
      width: 100%;
      height: 600px;
      background: url("./../static/img/about/int/bg2.png") no-repeat center;
      background-size: cover;
      clear: both;
      .third-inf{
        width: 1134px;
        margin: 0 auto;
        padding-top: 50px;
        .third-txt{
          text-indent: 40px;
          font-size: 18px;
          text-align: left;
          line-height: 30px;
          color: #666666;
        }
      }

    }
    .fourth{
      position: relative;
      padding-top: 52px;
      padding-bottom: 80px;
      width: 1200px;
      margin: 0 auto;
      height: 600px;
      text-align: center;
      //overflow: hidden;
      .fourth-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .fourth-box1{
          width: 556px;
          padding-right: 50px;
          .fourth-box1-2{
            color: #666666;
            font-size: 16px;
            text-indent: 40px;
            text-align: left;
            line-height: 30px;
          }
        }
        .fourth-box2{
          width: 745px;
        }
        .fourth-box3{
          width: 400px;
        }
      }
      .bottom-box{
        margin-top: 30px;
      }
}


    .fifth {
      background: #ededed;
      padding-top: 72px;
      padding-bottom: 80px;
      text-align: center;

      .title {
        color: #191e4a;
        font-size: 30px;
        letter-spacing: 4px;
      }

      .under_tit {
        color: #191e4a;
        font-size: 18px;
        padding-top: 8px;
      }

      label {
        position: relative;
        color: #666666;
        font-size: 18px;
        padding-right: 2px;

        &.required {
          &:after {
            position: absolute;
            right: 4px;
            content: "*";
            color: #f56c6c;
          }
        }
      }

      .form_box {
        padding: 0 60px;

        .top_box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 20px;
          margin-top: 60px;

          .input_box {
            display: flex;
            line-height: 42px;

            input {
              height: 100%;
              font-size: 18px;
              line-height: 42px;
              height: 42px;
              padding-left: 2em;
            }
          }
        }

        .center_box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 240px;

          textarea {
            padding-top: 10px;
            width: 1024px;
            height: 100%;
            border-radius: 4px;
            font-size: 18px;
            line-height: 20px;
            text-indent: 2em;
          }
        }

        .submit_btn {
          width: 194px;
          height: 60px;
          margin-top: 40px;
        }
      }
    }
  }
  .node-img1{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: 10px;

  }
  .node-img2{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: -22px;

  }
</style>
