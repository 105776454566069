<template>
  <div class="about">
    <div class="second">

      <img class="second-box1 animated fadeIn" src="../static/img/about/int/tit1.png" height="76" width="158"/>
      <div class="second-box bottom-box">
        <div class="second-box3 animated fadeInLeft">

          <img src="../static/img/about/int/img3.png" height="510" width="510"/>
        </div>
        <div class="second-box1 animated fadeInRight">
          <div class="second-box1-2" style="text-align: justify">
            河南经方云科技有限公司是一家立足于河南、专注中医大数据和人工智能技术的高科技公司。经方云是国家高新技术企业、省级双软企业、2022年获省科技厅批准省级工程技术研究中心。
          </div>
          <div  class="second-box1-2">
            公司以“传承中医文化、传播健康知识、引领中医智慧医疗，让世界共享中医”为己任，专注于人工智能和中医大数据分析技术，致力于将云计算、人工智能等先进的信息技术运用于传统中医药领域，以实现中医药的现代化发展。
          </div>
        </div>
      </div>
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="third">
      <img v-show="scroll1" ref="scroll0" src="../static/img/about/int/tit2.png" height="76" width="213"/>
      <div v-show="scroll1" ref="scrolla" class="third-inf">
        <div class="third-inf1">
          <div style="margin-left: 50px"><img src="../static/img/about/int/about-img1.png" height="360" width="326"/></div>
          <div class="third-inf2">
            <div class="third-inf2-1 third-inf2-1s">
              <div class="third-inf2-2">●</div>
              <div class="third-inf2-3">
                2019年10月，中共中央、国务院印发《关于促进中医药传承创新发展的意见》提出“2022年县乡村中医服务全覆盖”的重大目标，同时给出明确的实现路径：鼓励开发和使用中医辅助诊疗系统，提升基层中医诊疗服务能力。

              </div>
            </div>
            <div class="third-inf2-1">
              <div class="third-inf2-2">●</div>
              <div class="third-inf2-3">
                国家“十四五”规划明确指出，把发展中医药事业放在当前突出位置，坚持发挥中医药特色优势和多元价值作用，坚持突出中医药医疗服务核心价值，为健康中国建设、全面建设社会主义现代化国家作出应有贡献。
              </div>
            </div>
            <div class="third-inf2-1">
              <div class="third-inf2-2">●</div>
              <div class="third-inf2-3">
                习近平总书记2021年5月12日在河南省南阳市考察调研时指示：传承精华、守正创新。要深入发掘中医药宝库中的精华，推进产学研一体化，推进中医药产业化、现代化，让中医药走向世界。
              </div>
            </div>
            <div class="third-inf2-1">
              <div class="third-inf2-2">●</div>
              <div class="third-inf2-3">
                国家中医药管理局2021年6月11日：为进一步深入学习贯彻落实习近平总书记在南阳考察时对中医药工作的重要指示精神，抢抓大好机遇，加大支持力度，加快推动河南省中医药传承创新发展。将加快推动河南中医药事业和产业发展作为一项重要的任务。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="node-img2">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="fourth">
      <img v-show="scroll2" ref="scrollb" src="../static/img/about/int/tit3.png" height="78" width="338"/>
      <div v-show="scroll2" class="fourth-box bottom-box">
        <div class="fourth-box3" ref="scrollc">
          <img src="../static/img/about/int/img2.png" height="322" width="613"/>
        </div>
        <div class="fourth-box1" ref="scrolld">
          <div class="fourth-box1-2">
            如今，经方研究专业团队与信息化技术专业团队相结合，成功将经方研究的成果与Al技术、大数据及云计算完美对接，研发出"国医经方人工智能辅助诊断平台"这个中医辅助诊断系统，实现人工智能技术第一次成功被应用到中医药领域之中，使中医诊治也具有标准化、路径化 。
          </div>
          <div class="fourth-box1-2">
            "国医经方人工智能辅助诊断平台"在中医领域产生了重大影响，是国医创新发展的典范之作。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNav from "@/components/Nav/TopNav.vue";
  import Footer from "@/components/Footer/Footer.vue";

  export default {
    data() {
      return {
        activeName: 'first',
        formData: {
          name: "",
          phone: "",
          email: "",
          content: ""
        },
        isLoading: false,
        scroll1: false,
        scroll2: false,
        index: '0'
      };
    },
    mounted() {
      window.addEventListener('scroll',this.handleScroll,true)
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
        this.index = tab.index
        console.log(this.index)
      },
      handleScroll(){
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // console.log(scrollTop)
        if (scrollTop >= 600 && !this.scroll1) {
          this.scroll1 = true
          this.$refs.scrolla.classList.add('animated', 'fadeIn')
          this.$refs.scroll0.classList.add('animated', 'fadeIn')
        }
        if (scrollTop >= 1300 && !this.scroll2) {
          this.scroll2 = true
          this.$refs.scrollb.classList.add('animated', 'flipInY')
          this.$refs.scrollc.classList.add('animated', 'fadeInLeft')
          this.$refs.scrolld.classList.add('animated', 'fadeInRight')
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll',this.handleScroll,true)
    },
  };
</script>

<style lang="scss" scoped>
  .about {
    .first {
      padding-top: 10px;
      margin-top: 80px;
      width: 100%;
      height: 360px;
      background: url("./../static/img/about/int/bg1.png") no-repeat center;
      background-size: cover;
      clear: both;
      .top-tab{
        width: 1200px;
        margin: 0 auto;
        color: white;
      }
      .top-tit{
        color: white;
        font-size: 16px;
        text-align: left;
        width: 150px;
        position: relative;
        top: -50px;
        left: 0;
      }

      /deep/ .el-tabs__nav-wrap::after{

        height: 1px !important;
        background-color: #E4E7ED;
      }
      /deep/ .el-tabs__nav{
        padding-left: 560px;
      }
      /deep/ .el-tabs__active-bar{
        margin-left: 560px;

      }
      /deep/ .el-tabs__item{
        color: white;
        font-size: 16px;
        height: 46px;
        padding: 0 20px;
      }
      /deep/.el-tabs__active-bar{
        background-color: #fff !important;
        height: 3px;
      }
      /deep/.is-active{
        font-weight: 550;
        font-size: 16px;
      }

    }
    .second {
      position: relative;
      padding-top: 52px;
      padding-bottom: 80px;
      width: 1200px;
      margin: 0 auto;
      height: 800px;
      text-align: center;
      //overflow: hidden;
      .second-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .second-box1{
          width: 556px;
          padding-right: 50px;
          .second-box1-2{
            color: #666666;
            font-size: 16px;
            text-indent: 40px;
            text-align: left;
            line-height: 36px;
          }
        }
        .second-box2{
          width: 745px;
        }
        .second-box3{
          width: 400px;
        }
      }
      .bottom-box{
        margin-top: 30px;
      }
    }
    .third{
      padding-top: 50px;
      width: 100%;
      height: 600px;
      background: url("./../static/img/about/int/bg2.png") no-repeat center;
      background-size: cover;
      clear: both;
      .third-inf{
        width: 1134px;
        margin: 0 auto;
        padding-top: 50px;
        .third-txt{
          text-indent: 40px;
          font-size: 18px;
          text-align: left;
          line-height: 30px;
          color: #444444;
        }
        .third-inf1{
          display: flex;
          justify-content: left;
          align-items: first;
          .third-inf2{
            margin-left: 60px;
            .third-inf2-1{
              display: flex;
              justify-content: left;
              align-items: first;
              width: 700px;
              margin-top: 6px;
              .third-inf2-2{
                width: 20px;
                height: 20px;
                color: #444444;
                font-size: 20px;
                margin-right: 10px;
              }
              .third-inf2-3{
                text-align: left;
                line-height: 30px;
                width: 650px;
                color: #666666;

              }
            }
            .third-inf2-1s{
              margin-top: 0;
            }

          }


        }
      }

    }
    .fourth{
      position: relative;
      padding-top: 52px;
      padding-bottom: 80px;
      width: 1200px;
      margin: 0 auto;
      height: 600px;
      text-align: center;
      //overflow: hidden;
      .fourth-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .fourth-box1{
          width: 556px;
          padding-right: 50px;
          .fourth-box1-2{
            color: #666666;
            font-size: 16px;
            text-indent: 40px;
            line-height: 30px;
            text-align: justify;
          }
        }
        .fourth-box2{
          width: 745px;
        }
        .fourth-box3{
          width: 400px;
        }
      }
      .bottom-box{
        margin-top: 30px;
      }
}


    .fifth {
      background: #ededed;
      padding-top: 72px;
      padding-bottom: 80px;
      text-align: center;

      .title {
        color: #191e4a;
        font-size: 30px;
        letter-spacing: 4px;
      }

      .under_tit {
        color: #191e4a;
        font-size: 18px;
        padding-top: 8px;
      }

      label {
        position: relative;
        color: #666666;
        font-size: 18px;
        padding-right: 2px;

        &.required {
          &:after {
            position: absolute;
            right: 4px;
            content: "*";
            color: #f56c6c;
          }
        }
      }

      .form_box {
        padding: 0 60px;

        .top_box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 20px;
          margin-top: 60px;

          .input_box {
            display: flex;
            line-height: 42px;

            input {
              height: 100%;
              font-size: 18px;
              line-height: 42px;
              height: 42px;
              padding-left: 2em;
            }
          }
        }

        .center_box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 240px;

          textarea {
            padding-top: 10px;
            width: 1024px;
            height: 100%;
            border-radius: 4px;
            font-size: 18px;
            line-height: 20px;
            text-indent: 2em;
          }
        }

        .submit_btn {
          width: 194px;
          height: 60px;
          margin-top: 40px;
        }
      }
    }
  }
  .node-img1{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: 10px;

  }
  .node-img2{
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    top: -22px;

  }
</style>
